import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import { colors } from "../../theme/theme";

interface Props {
  label: string,
  key: React.Key,
}

const TabOutlined = styled((props: Props) => (
  <Tab {...props}/>
))(({theme}) => ({
  borderWidth: "2px",
  borderRadius: "50px",
  borderStyle: "solid",
  borderColor: colors.tabs.selectedOutlined,
  margin: "12px 0px 12px 0px",
  padding: "0px 20px 0px 20px",
  color: colors.tabs.selectedOutlined,
  '&.Mui-selected': {
    backgroundColor: colors.tabs.selectedOutlined,
    color: "white",
  }
}));

export default TabOutlined;