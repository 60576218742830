import Catalog from "../../components/Catalog/Catalog";
import Item from "../../components/Catalog/Item";
import Section from "../../components/Catalog/Section";
import Center from "../../components/Center";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Questions from "../../components/Questions";
import { theme } from "../../theme/theme";
import { Helmet } from "react-helmet";

const catalog: Array<Section> = [
  {
    title: "Pesäkalusto",
    items: [
      new Item("Pohjavaneri", 13),
      new Item("Vesikatto", 20),
      new Item("Pesäosasto LG", 22),
      new Item("Pesäosasto LG eristetty", 35),
      new Item("Pesäosasto Farrar", 20),
      new Item("Pesäosasto Farrar eristetty", 30),
      new Item("Pesäosasto Farrar kasaamaton", 15),
      new Item("Jakopesä", 45, "€", "sis. pohja, katto, 5 kehäinen osasto"),
      new Item("Välikatto", 3),
      new Item("Kehälistat LG Farrar", 0.6),
      new Item("Mehiläisvaha pohjuke", 17, "€/kg"),
      new Item("Sulkuristikkokehys", 6, "€", "390 mm x 490 mm"),
    ],
  },
  {
    title: "Tuotteet",
    items: [
      new Item("Kuningattaret", 50, "€", "italialaisrotuiset"),
      new Item("Talvehtineet yhdyskunnat", 350),
      new Item("Jaoke 9 kehäinen", 200),
      new Item("Jaoke 5 kehäinen", 150),
      // new Item("Hunaja 1 kg"),
      // new Item("Tynnyrihunaja", "Kysy"),
      new Item("Lajihunaja", "Kysy"),
    ],
  },
  {
    title: "Palvelut",
    items: [
      new Item("Rahtilinkous", "Kysy"),
      new Item("Vahansulatus", 0.3, "€/kehä"),
      new Item("Kehien pesu", 0.3, "€/kehä"),
      new Item("Mehiläispesien vuokraus", "Kysy"),
    ],
  },
];

const BeekeeperPage = () => {
  return (
    <div>
      <Helmet>
        <title>Viidan Tila - Mehiläistarhaajille</title>
        <meta
          name="description"
          content="Mehiläistarhaajille pesäkalustoa, tuotteita ja palveluita."
        />
        <meta
          name="keywords"
          content="mehiläistarhaaja, mehiläistarhaus, pesäkalusto, Pori"
        />
      </Helmet>
      <Header
        title="Mehiläistarhaajalle"
        imagePath={require("../../assets/images/bee_box_1_header.webp")}
      />
      <Center>
        <div
          style={{
            paddingTop: theme.spacing(12),
            paddingBottom: theme.spacing(12),
          }}
        >
          <Catalog sections={catalog} />
        </div>
      </Center>
      <Questions title="Kysyttävää tuotteista tai palveluista?" />
      <Footer />
    </div>
  );
};

export default BeekeeperPage;
