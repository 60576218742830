class Item {
  _name: string;
  _price?: number | null | "Kysy" | "Loppu" = null;
  _description?: string | null = null;
  _unit?: string | null = null;

  get price() {
    if (this._price === null || this._price === undefined) {
      return "Kysy hintaa";
    } else if (this._price === "Kysy") {
      return "Kysy";
    } else if (this._price === "Loppu") {
      return "Loppu";
    } else {
      return `${this._price} ${this._unit ?? "€"}`;
    }
  }

  get description() {
    if (this._description == null) {
      return "";
    }

    return `(${this._description})`;
  }

  get name() {
    return this._name;
  }

  constructor(
    name: string,
    price?: number | "Kysy" | "Loppu",
    unit?: string,
    description?: string
  ) {
    this._name = name;
    this._price = price;
    this._description = description;
    this._unit = unit;
  }
}

export default Item;
