import animationData from "../assets/lottie/message_send.json";
import Lottie from "lottie-react";
import Modal from "@mui/material/Modal";
import { FC } from "react";
import { Typography } from "@mui/material";

interface Props {
  isOpen: boolean;
  onCompleted: () => void;
}

const EmailSendSuccessed: FC<Props> = (props: Props) => {

  return (
    <Modal open={props.isOpen}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Lottie
          animationData={animationData}
          height={200}
          width={200}
          loop={false}
          onComplete={props.onCompleted}
        />

        <Typography variant="body1" color="white" fontSize={24} textAlign="center">
          Viestisi on vastaanotettu ja olemme yhteydessä sinuun mahdollisimman
          pian!
        </Typography>
      </div>
    </Modal>
  );
};

export default EmailSendSuccessed;
