import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Logo from "./components/Logo";
import { Tab } from "./components/TabShell/Tab";
import { TabShell } from "./components/TabShell/TabShell";
import BeekeeperPage from "./pages/beekeeper/BeekeeperPage";
import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";
import PollinationPage from "./pages/pollination/PollinationPage";
import ProductsPage from "./pages/products/ProductsPage";
import { theme } from "./theme/theme";
import ScrollToTop from "./hooks/scrollToTop";

const tabs: Array<Tab> = [
  { text: "ETUSIVU", path: "/etusivu", page: <HomePage /> },
  { text: "TUOTTEET", path: "/tuotteet", page: <ProductsPage /> },
  {
    text: "MEHILÄISTARHAAJALLE",
    path: "/mehilaistarhaajalle",
    page: <BeekeeperPage />,
  },
  {
    text: "PÖLYTYSPALVELU",
    path: "/polytyspalvelu",
    page: <PollinationPage />,
  },
  { text: "OTA YHTEYTTÄ", path: "/yhteystiedot", page: <ContactPage /> },
];

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <CssBaseline />
      <TabShell tabs={tabs} logo={<Logo />}>
        <Routes>
          <Route index element={tabs[0].page} />
          <Route path={tabs[0].path} element={tabs[0].page} />
          <Route path={tabs[1].path} element={tabs[1].page} />
          <Route path={tabs[2].path} element={tabs[2].page} />
          <Route path={tabs[3].path} element={tabs[3].page} />
          <Route path={tabs[4].path} element={tabs[4].page} />
          <Route path="/*" element={tabs[0].page} />
        </Routes>
      </TabShell>
    </ThemeProvider>
  );
}

export default App;
