import { Typography, useMediaQuery } from "@mui/material";
import { MessageForm } from "../../components/MessageForm";
import { theme } from "../../theme/theme";

const MessageBox = () => {

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
    style={{
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
      paddingLeft: theme.spacing(isMobile ? 4 : 8),
      paddingRight: theme.spacing(isMobile ? 4 : 8),
      boxShadow: theme.spacing(1),
      borderRadius: theme.spacing(2),
      backgroundColor: "white",
    }}>
      <Typography variant="h3" paddingBottom={2} lineHeight={1}>Lähetä viesti</Typography>
      <Typography variant="body1" paddingBottom={4}>Voit lähettää myös viestin. Pyrin vastaamaan siihen noin vuorokauden kuluessa.</Typography>
      <MessageForm />
    </div>
  );
};

export default MessageBox;
