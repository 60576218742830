import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion } from "framer-motion";
import { FC } from "react";
import LinkButton from "../../components/LinkButton";
import { theme } from "../../theme/theme";

interface IProps {
  imagePath: string;
  title: string;
  text: string;
  buttonText: string;
  isImageOnLeft: boolean;
  onClick: () => void;
}

const Section: FC<IProps> = (props: IProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const textSectionHeight = isMobile ? undefined : "400px";
  const alignment = isMobile ? "center" : "start";

  return (
    <Grid container columns={5} columnSpacing={4} rowSpacing={6}>
      <Grid
        item
        lg={3}
        md={2.5}
        xs={5}
        order={props.isImageOnLeft && isMobile === false ? 0 : 1}
      >
        <motion.div
          style={{
            height: isMobile ? "350px" : "470px",
            position: "relative",
          }}
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            opacity: { type: "tween", ease: "linear", duration: "0.5" },
          }}
          viewport={{ once: true, amount: 0.60 }}
        >
          <img
            alt={props.title}
            style={{
              position: "absolute",
              objectFit: "cover",
              overflow: "hidden",
              height: "100%",
              width: "100%",
              borderRadius: theme.spacing(2),
            }}
            src={props.imagePath}
          ></img>
        </motion.div>
      </Grid>

      <Grid
        item
        lg={2}
        md={2.5}
        xs={5}
        order={props.isImageOnLeft && isMobile === false ? 1 : 0}
      >
        <div
          style={{
            height: textSectionHeight,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: alignment,
          }}
        >
          <Typography
            variant="h2"
            paddingBottom={3}
            textAlign={alignment}
            display="inline"
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            paddingBottom={6}
            textAlign={alignment}
            sx={{ opacity: 0.8 }}
          >
            {props.text}
          </Typography>
          <LinkButton
            onClick={props.onClick}
            text={props.buttonText}
            borderRadius="50px"
            variation="outlined"
            padding="12px 24px 12px 24px"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Section;

/*
          <div
            style={{
              backgroundColor: colors.primaryColor,
              position: "absolute",
              width: 200,
              height: isMobile ? "250px" : "370px",
              left: props.isImageOnLeft ? "-16px" : undefined,
              bottom: props.isImageOnLeft ? "-16px" : undefined,
              right: props.isImageOnLeft ? undefined : "-16px",
              top: props.isImageOnLeft ? undefined : "-16px",
              borderRadius: theme.spacing(2),
              opacity: 0.2,
            }}
          ></div>

*/
