const constants = {
  address: "Kiviveistämöntie 69",
  postalCode: "28760",
  city: "Pori",
  email: "viidantila@gmail.com",
  phoneNumber: "040 0725 493",
  name: "Markus Ruusunen",
};

export default constants;
