import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import Center from "../../components/Center";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Questions from "../../components/Questions";

const PollinationPage = () => {
  return (
    <div>
      <Helmet>
        <title>Viidan Tila - Pölytyspalvelu</title>
        <meta name="description" content="Pölytyspalvelua isoille sekä pienille maatiloille. Myös kotipuutarhureille." />
        <meta name="keywords" content="pölytyspalvelu, maatila, mehiläistarha, Pori"/>
      </Helmet>
      <Header title="Pölytyspalvelu" imagePath={require("../../assets/images/bee_box_field_5.webp")} />
      <Center>
        <Typography variant="h3" paddingTop={12} paddingBottom={2}>Pölytys</Typography>
        <Typography variant="body1" paddingBottom={10}>
          Onnistunut pölytys kasvattaa sadon määrää ja laatua. Pölytyspalvelussa
          viemme mehiläispesiä viljelysten välittömään läheisyyteen pölyttämään
          viljelykasveja. Esimerkiksi härkäpapu, rypsi, rapsi, kumina, tattari,
          apilat, mansikka omena, puutarhavadelma, herukat ja pensasmustikka
          hyötyvät lähellä sijaitsevista mehiläispesistä. Pölytyksen ansiosta
          peltokasvien siemenet myös tuulentuvat hyvin.
        </Typography>
        <Typography variant="h3" paddingBottom={2}>Vastuut ja velvollisuudet</Typography>
        <Typography variant="body1" paddingBottom={10}>
          Vastaamme siitä että mehiläisyhdyskunnat ovat sopivia ja pesiä
          riittävästi. Tarvittava mehiläispesien määrä hehtaaria kohden riippuu
          kasvilajista, viljelmän muodosta ja muiden pölyttäjähyönteisten
          määrästä. Emme ole vastuussa sääolojen vuoksi epäonnistuneesta
          pölytyksestä tai jos mehiläisten lentoalueelle kukkii joku enemmän
          kiinnostava kasvi. Viljelijän tulee muistaa, että pölyttäjille
          vaarallisia kasvinsuojeluaineita ei saa käyttää pölytyspalvelun aikana
          eikä vuorokautta (tai käytetyn aineen käyttöohjeen mukaista varoaikaa)
          ennen palvelun alkua.
        </Typography>
        <Typography variant="h3" paddingBottom={2}>Hinta</Typography>
        <Typography variant="body1" paddingBottom={10}>
          Palvelun hintaan vaikuttavat mm. matka-aika ja pesien määrä. Jokainen
          tapaus on erilainen ja lopullinen hinta määräytyy tarkemmin kun olemme
          perehtyneet tarkemmin tarpeeseenne. Ota siis yhteyttä!
        </Typography>
      </Center>
      <Questions title="Kysyttävää pölytyspalvelusta"/>
      <Footer />
    </div>
  );
};
/*
<img src={headerImage}></img>
<Header imagePath='../images/bee_box_field_5.jpg'/>
<img src={require("../../images/bee_box_field_5.jpg")}></img>
<Header imagePath='../../images/bee_box_field_5.jpg'/>
    
*/
export default PollinationPage;
