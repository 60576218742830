import { ButtonBase } from "@mui/material";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { ArrowRight } from "../../icons/icons";
import { colors } from "../../theme/theme";

interface IProps {
  onClick: () => void;
}

const ServicesButton: FC<IProps> = (props: IProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <ButtonBase
      sx={{
        borderRadius: 2,
        width: "100%",
        height: "500px",
        overflow: "hidden",
        display: "flex",
        position: "relative",
        backgroundColor: colors.secondaryColor,
        filter: "drop-shadow(0px 32px 32px #00000018)",
        WebkitFilter: "drop-shadow(0px 32px 32px #00000018)",
      }}
      onClick={props.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <motion.div
        animate={{
          scale: isHovered ? 1.05 : 1,
        }}
        transition={{ duration: 0.3 }}
        style={{
          position: "absolute",
          fontFamily: "Shadows Into Light",
          fontSize: "50px",
          fontWeight: "bold",
          color: "black",
          letterSpacing: 1,
          left: "0px",
          right: "0px",
          top: "0px",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          padding: "60px 40px 120px 40px",
        }}
      >
        Muita tuotteita ja palveluita
      </motion.div>

      <motion.div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "0px",
          right: "20px",
          display: "flex",
          justifyContent: "end",
        }}
        transition={{ duration: 0.3, type: "spring" }}
        animate={{
          x: isHovered ? [0, 10, 0] : 0,
        }}
      >
        <ArrowRight color="black" />
      </motion.div>
    </ButtonBase>
  );
};

export default ServicesButton;
