import { ButtonBase } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const Logo: FC<{ color?: string }> = ({ color }) => {
  const navigator = useNavigate();
  return (
    <ButtonBase
      onClick={() => navigator("etusivu")}
      style={{ padding: "12px" }}
    >
      <img
        src={require("../assets/images/logo_text.png")}
        height="48p"
        alt="Logo"
      />
    </ButtonBase>
  );
};

export default Logo;
