import { FC, ReactElement } from "react";
import { colors, theme } from "../theme/theme";

interface Props {
  icon?: ReactElement;
  title: string;
  info: string;
  details?: string;
  iconPlacement?: "left" | "top" | undefined;
}

const ContactInfoItem: FC<Props> = (props: Props) => {
  const isIconOnTop =
    props.iconPlacement === undefined ||
    props.iconPlacement === "top" ||
    props.iconPlacement == null;

  const flexDirection = isIconOnTop ? "column" : "row";
  const textAlignment = isIconOnTop ? "center" : "start";
  const iconBottomMargin = isIconOnTop ? theme.spacing(3) : 0;
  const iconMarginRight = isIconOnTop ? 0 : theme.spacing(3);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: flexDirection,
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: colors.contactInfoItem.background,
          width: "64px",
          height: "64px",
          borderRadius: "35px",
          marginBottom: iconBottomMargin,
          marginRight: iconMarginRight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.icon}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: textAlignment,
          justifyContent: textAlignment,
        }}
      >
        <div
          style={{
            fontSize: "16px",
            letterSpacing: 1,
            lineHeight: 1,
            marginBottom: theme.spacing(1),
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            fontWeight: "500",
            letterSpacing: 1,
            lineHeight: 1,
            textAlign: "center",
          }}
        >
          {props.info}
        </div>
        {props.details && (
          <div
            style={{
              fontWeight: "500",
              letterSpacing: 1,
              lineHeight: 1,
              marginTop: theme.spacing(1),
            }}
          >
            {props.details}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactInfoItem;
