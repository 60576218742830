import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const EmailSendFailed: FC<Props> = (props: Props) => {
  const handleClose = () => {
    props.onClick();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
      Viestin lähetys epäonnistui!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Ota yhteyttä puhelimella tai sähköpostilla
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailSendFailed;
