import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useMemo } from "react";
import { theme } from "../theme/theme";

const Map = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  });

  const center = useMemo(
    () => ({ lat: 61.52964352709728, lng: 21.692926995696258 }),
    []
  );

  if (isLoaded === false) return <div>Loading...</div>;

  const mapOptions = {
    gestureHandling: "cooperative",
  }

  return (
    <GoogleMap
      options={mapOptions}
      zoom={14}
      center={center}
      mapContainerStyle={{ height: theme.spacing(60), width: "100%" }}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

export default Map;
