import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { colors, theme } from "../theme/theme";
import { styled } from "@mui/material/styles";

import { Box } from "@mui/system";
import Center from "./Center";
import constants from "../constants";

const Title = styled(Typography)(({ theme }) => ({
  color: "white",
  variant: "h4",
  fontSize: "24px",
  fontWeight: "bold",
  letterSpacing: 1.4,
  textAlign: "center",
  paddingBottom: theme.spacing(2),
}));

const Body = styled(Typography)({
  color: "white",
  variant: "body",
  fontSize: "16px",
  letterSpacing: 1.4,
  textAlign: "center",
});

function Footer() {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const rowSpacing = isMobile ? 8 : 0;

  const alignmentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: isMobile ? "center" : "start",
  };

  return (
    <div style={{ backgroundColor: colors.footer.background }}>
      <Center>
        <Grid
          container
          columns={3}
          paddingTop={isMobile ? 14 : 10}
          paddingBottom={isMobile ? 14 : 4}
          rowSpacing={rowSpacing}
          position="relative"
        >
          <Grid
            item
            md={1}
            xs={3}
            sx={{
              position: "relative",
              top: "-22px",
              ...alignmentStyle,
            }}
          >
            <img
              src={require("../assets/images/logo.webp")}
              width="200p"
              alt="Logo"
            />
          </Grid>

          <Grid item md={1} xs={3}>
            <Box sx={alignmentStyle}>
              <Title>Osoite</Title>
              <Body>{constants.address}</Body>
              <Body>
                {constants.postalCode}, {constants.city}
              </Body>
              {/*<Body>Y-Tunnus: 2506170-7</Body>*/}
            </Box>
          </Grid>
          <Grid item md={1} xs={3}>
            <Box sx={alignmentStyle}>
              <Title>Ota yhteyttä</Title>
              <Body>Markus Ruusunen</Body>
              <Body>{constants.phoneNumber}</Body>
              <Body>{constants.email}</Body>
            </Box>
          </Grid>
        </Grid>
      </Center>
      <Divider sx={{ backgroundColor: colors.footer.divider }} />
      <div style={{ backgroundColor: colors.footer.background }}>
        <Typography
          variant="body1"
          sx={{
            color: colors.footer.copyright,
            padding: "12px 0px 12px 0px",
            textAlign: "center",
            fontSize: "14px",
            letterSpacing: 1.2,
          }}
        >
          Copyright 2022 by Viidan Tila
        </Typography>
      </div>
    </div>
  );
}

export default Footer;
