import { FC } from "react";

const HiveIcon: FC<{
  color?: string | undefined;
  width?: string;
  height?: string;
}> = ({ color = "black", width = "48px", height = "48px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 48 48"
      fill={color}
      strokeWidth="0px"
    >
      <path d="M34.05 23.2q-.45 0-.95-.275-.5-.275-.75-.675L29.65 18q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.25q.25-.4.75-.675t.95-.275h4.6q.45 0 .95.275.5.275.75.675l2.7 4.25q.25.4.25.95 0 .55-.25.95l-2.7 4.25q-.25.4-.75.675t-.95.275Zm-12.2 7q-.45 0-.95-.275-.5-.275-.75-.675l-2.7-4.3q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.3q.25-.4.75-.675t.95-.275h4.4q.45 0 .95.275.5.275.75.675l2.7 4.3q.25.4.25.95 0 .55-.25.95l-2.7 4.3q-.25.4-.75.675t-.95.275Zm0-13.9q-.45 0-.95-.275-.5-.275-.75-.675l-2.7-4.25q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.25q.25-.4.75-.675T21.85 4h4.4q.45 0 .95.275.5.275.75.675l2.7 4.25q.25.4.25.95 0 .55-.25.95l-2.7 4.25q-.25.4-.75.675t-.95.275Zm-12.4 6.9q-.45 0-.95-.275-.5-.275-.75-.675L5.05 18q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.25q.25-.4.75-.675t.95-.275h4.6q.45 0 .95.275.5.275.75.675l2.7 4.25q.25.4.25.95 0 .55-.25.95l-2.7 4.25q-.25.4-.75.675t-.95.275Zm0 13.9q-.45 0-.95-.275-.5-.275-.75-.675l-2.7-4.25q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.25q.25-.4.75-.675t.95-.275h4.6q.45 0 .95.275.5.275.75.675l2.7 4.25q.25.4.25.95 0 .55-.25.95l-2.7 4.25q-.25.4-.75.675t-.95.275Zm12.4 6.9q-.45 0-.95-.275-.5-.275-.75-.675l-2.7-4.25q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.25q.25-.4.75-.675t.95-.275h4.4q.45 0 .95.275.5.275.75.675l2.7 4.25q.25.4.25.95 0 .55-.25.95l-2.7 4.25q-.25.4-.75.675t-.95.275Zm12.2-6.9q-.45 0-.95-.275-.5-.275-.75-.675l-2.7-4.25q-.25-.4-.25-.95 0-.55.25-.95l2.7-4.25q.25-.4.75-.675t.95-.275h4.6q.45 0 .95.275.5.275.75.675l2.7 4.25q.25.4.25.95 0 .55-.25.95l-2.7 4.25q-.25.4-.75.675t-.95.275Z" />
    </svg>
  );
};

export default HiveIcon;
