import Center from "../../components/Center";
import Questions from "../../components/Questions";
import { colors, theme } from "../../theme/theme";
import InfoSection from "./InfoSection";
import MessageBox from "./MessageBox";
import Map from "../../components/Map";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContactSection = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) {
    return (
      <div>
        <Questions title="Kysyttävää?" />
        <MessageBox />
        <Map />
      </div>
    );
  } else {
    return (
      <div style={{ position: "relative" }}>
        <Questions title="Kysyttävää?" buttonText={null}/>

        <div
          style={{
            backgroundColor: colors.lightGreyBackground,
            height: "700px",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            zIndex: "0",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Map />
        </div>

        <Center>
          <div
            style={{
              display: "flex",
              top: "400px",
              maxWidth: "1200px",
              position: "absolute",
              paddingRight: "16px",
            }}
          >
            <div
              style={{
                flex: "2",
                paddingRight: theme.spacing(4),
              }}
            >
              <MessageBox />
            </div>

            <div
              style={{
                display: "flex",
                flex: "1",
                alignItems: "center",
                paddingLeft: theme.spacing(4),
              }}
            >
              <InfoSection />
            </div>
          </div>
        </Center>
      </div>
    );
  }
};

export default ContactSection;
