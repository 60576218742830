import IconButton from "@mui/material/IconButton";
import { FC, ReactNode, useState } from "react";
import { Rect, useRect } from "react-use-rect";
import { Tab } from "./Tab";
import TabAppBar, { TabStyles } from "./TabAppBar";
import { TabShellProvider } from "./TabShellContext";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import ShellMenu from "./ShellMenu";

import rippedPaper from "../../assets/images/ripped-paper.png";

interface Props {
  menu?: ReactNode;
  children?: ReactNode | ReactNode[];
  logo?: ReactNode;
  tabs: Array<Tab>;
  initialTabIndex?: number;
  mobileMaxWidth?: number;
}

export const TabShell: FC<Props> = (props: Props) => {
  const [appBarDimension, setAppBarDimension] = useState<Rect | null>(null);
  const [appBarRef] = useRect(setAppBarDimension, { resize: true });

  const [logoDimension, setLogoDimension] = useState<Rect | null>(null);
  const [logoRef] = useRect(setLogoDimension, { resize: true });

  const [headerDimension, setHeaderDimension] = useState<Rect | null>(null);
  const [headerRef] = useRect(setHeaderDimension, { resize: true });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const appBarHeight = appBarDimension ? appBarDimension.height : 0;
  const headerWidth = headerDimension ? headerDimension.width : 0;
  const logoWidth = logoDimension ? logoDimension.width : 0;
  const appBarWidth = appBarDimension ? appBarDimension.width : 0;

  const isMobile: boolean =
    headerWidth <
    Math.min(
      props.mobileMaxWidth ?? Number.MAX_VALUE,
      logoWidth + appBarWidth + 64
    );

  const menuButtonClickHandler = () => {
    setIsMenuOpen(true);
  };

  return (
    <TabShellProvider initialTabIndex={props.initialTabIndex}>
      <div
        style={{
          position: "absolute",
          left: "0",
          top: appBarHeight,
          right: "0",
          bottom: "0",
        }}
      >
        {props.children}
        <Drawer
          anchor="right"
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          <ShellMenu
            tabs={props.tabs}
            onTabClick={() => setIsMenuOpen(false)}
          />
        </Drawer>
      </div>

      <div
        style={{
          left: "0",
          right: "0",
          top: appBarHeight - 3,
          position: "fixed",
          backgroundImage: `url(${rippedPaper})`,
          backgroundRepeat: "repeat-x",
          filter: "drop-shadow(0px 0px 32px #000000AA)",
          WebkitFilter: "drop-shadow(0px 0px 32px #000000AA)",
          objectFit: "cover",
          height: "20px",
        }}
      ></div>

      <div
        style={{
          position: "fixed",
          left: "0",
          right: "0",
          top: "0",
          height: appBarHeight,
          backgroundColor: "white",
        }}
      >
        <div
          ref={headerRef}
          style={{
            maxWidth: "1200px",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 0px 16px",
            }}
            ref={logoRef}
          >
            {props.logo}
          </div>

          {isMobile && (
            <IconButton onClick={menuButtonClickHandler}>
              <MenuIcon sx={{ margin: 2 }} />
            </IconButton>
          )}

          {!isMobile && (
            <div style={{ margin: "0px 16px 0px 0px" }}>
              <TabAppBar
                ref={appBarRef}
                tabs={props.tabs}
                lastTabStyle={TabStyles.outlined}
              />
            </div>
          )}
        </div>
      </div>
    </TabShellProvider>
  );
};
