import { createTheme } from "@mui/material";

const colorPalette = {
  blackDark: "#000000",
  black: "#212121",
  blackLight: "rgb(40 40 40)",

  primaryColorExtraDark: "rgb(10, 25, 5)",
  primaryColorDark: "rgb(14, 34, 7)",
  primaryColor: "rgb(31, 99, 6)",

  secondaryColor: "rgb(238, 192, 68)",

  lightWhite: "rgba(255, 255, 255, 0.35)",

  overlay: "rgba(118, 139, 88, 0.3)",
  overlayDark: "rgba(0, 0, 0, 0.2)",

  lightBrown: "rgb(242, 239, 233)",
  extraLightBrown: "rgb(248, 246, 241)",
  lightGrey: "rgb(244, 244, 244)",
  greyBrown: "rgb(234, 233, 226)",
};

export const colors = {
  primaryColorDark: colorPalette.primaryColorDark,
  primaryColor: colorPalette.primaryColor,
  secondaryColor: colorPalette.secondaryColor,
  lightBrownBackground: colorPalette.lightBrown,
  extraLightBrownBackground: colorPalette.extraLightBrown,
  lightGreyBackground: colorPalette.lightGrey,
  tabs: {
    unselectedText: colorPalette.blackLight,
    selectedText: colorPalette.blackDark,
    indicator: colorPalette.blackDark,
    selectedOutlined: colorPalette.primaryColor,
  },
  footer: {
    divider: "rgba(244, 244, 244, 0.1)",
    background: colorPalette.black,
    copyright: colorPalette.lightWhite,
  },
  header: {
    overlay: colorPalette.overlayDark,
  },
  linkButtonFilled: {
    background: colorPalette.secondaryColor,
    foregroud: colorPalette.black,
  },
  linkButtonFilled2: {
    background: colorPalette.primaryColor,
    foregroud: "white",
  },
  linkButtonOutlined: {
    border: colorPalette.primaryColor,
    foregroud: colorPalette.primaryColor,
  },
  contactInfoItem: {
    background: colorPalette.primaryColor,
    foreground: "white",
  },
  textField: {
    background: colorPalette.lightBrown,
    backgroundHover: colorPalette.greyBrown,
    backgroundFocused: colorPalette.lightBrown,
  },
  slideShow: {
    overlay: colorPalette.overlayDark,
  },
};

export const theme = createTheme({
  typography: {
    fontFamily: "Manrope",
    body1: {
      fontSize: 18,
      lineHeight: 1.8,
      letterSpacing: 1,
    },
    body2: {
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "2.4",
      opacity: "1",
      letterSpacing: 1.2,
    },
    h1: {
      fontSize: "80px",
      fontWeight: "bold",
      letterSpacing: 2,
      lineHeight: 1.8,
    },
    h2: {
      fontSize: "min(9vw, 48px)",
      fontWeight: 700,
      lineHeight: 1.8,
    },
    h3: {
      fontSize: "32px",
      letterSpacing: 2,
      fontWeight: "bold",
      color: "black",
    },
    h4: {
      fontSize: "25px",
    },
    button: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: colors.tabs.unselectedText,
          "&.Mui-selected": {
            color: colors.tabs.selectedText,
          },
        },
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "black",
          height: "4px",
        },
        root: {
          minHeight: "64px",
        },
      },
    },
  },
});
