import { Typography, useMediaQuery } from "@mui/material";
import { MessageForm } from "../../components/MessageForm";
import { theme } from "../../theme/theme";

const SendMessage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <div style={{
        maxWidth: isMobile ? undefined : "400px",
      }}>
        <Typography variant="h3" sx={{paddingBottom: 3}}>Lähetä viesti</Typography>
        <Typography variant="body1" paddingBottom={4}>
          Voit lähettää myös viestin. Pyrin vastaamaan siihen mahdollisimman pian.
        </Typography>
      </div>

      <MessageForm/>
    </div>
  );
};

export default SendMessage;