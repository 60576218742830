import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import { ReactNode, forwardRef, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab as TabModel } from "./Tab";
import TabOutlined from "./TabOutlined";
import { useTabShell } from "./TabShellContext";

export enum TabStyles {
  default,
  outlined,
}

interface Props {
  logo?: ReactNode;
  isMobileMode?: boolean;
  tabs: Array<TabModel>;
  lastTabStyle?: TabStyles;
}

const TabAppBar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const tabShellState = useTabShell();
  const location = useLocation();
  const navigate = useNavigate();

  // Handle tab click
  const onTabChangedHandler = (index: number) => {
    if (index !== tabShellState.tabIndex) {
      tabShellState.setTabIndex(index);
    }
    navigate({
      pathname: props.tabs[index].path,
    });
  };

  // Update right tab when page is refreshed
  useLayoutEffect(() => {
    for (let i = 0; i < props.tabs.length; i++) {
      if (location.pathname.match(props.tabs[i].path)) {
        if (i !== tabShellState.tabIndex) {
          tabShellState.setTabIndex(i);
        }
      }
    }
  });

  return (
    <div ref={ref}>
      <Tabs
        value={tabShellState.tabIndex}
        onChange={(_, value) => onTabChangedHandler(value)}
      >
        {props.tabs.map((tab) => {
          if (
            props.tabs.indexOf(tab) === props.tabs.length - 1 &&
            props.lastTabStyle === TabStyles.outlined
          ) {
            return <TabOutlined key={tab.text} label={tab.text} />;
          }
          return <Tab key={tab.text} label={tab.text} />;
        })}
      </Tabs>
    </div>
  );
});

export default TabAppBar;
