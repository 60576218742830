import { Typography } from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";
import { FC, useRef, useState } from "react";

interface Props {
  imagePath: any;
  height?: number;
  title: string;
  objectPosition?: string;
}

const Header: FC<Props> = ({
  imagePath,
  height = "min(400px, 50vh)",
  title,
  objectPosition,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["-72px 0px", "end start"],
  });
  const parallaxY = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);

  return (
    <div ref={ref}>
      <div style={{ height: height, position: "relative", overflow: "hidden" }}>
        <motion.img
          initial={{y: 0}}
          src={imagePath}
          onLoad={() => setIsLoaded(true)}
          height="100%"
          width="100%"
          alt="header"
          style={{
            objectFit: "cover",
            objectPosition: objectPosition,
            position: "absolute",
            left: "0px",
            y: parallaxY,
            right: "0px",
            bottom: "0px",
            alignSelf: "bottom",
            filter: "brightness(70%)",
          }}
        />

        <div
          style={{
            height: height,
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            left: "0px",
            top: "0px",
            right: "0px",
            bottom: "0px",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: isLoaded ? 1 : 0,
              y: isLoaded ? 0 : 50,
            }}
            transition={{
              opacity: { type: "tween", ease: "linear", duration: "0.2" },
              y: { type: "spring", duration: "2", mass: 1, stiffness: 200 },
            }}
            viewport={{ once: true }}
          >
            <Typography variant="h2" color="white">
              {title}
            </Typography>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Header;
