import { Typography } from "@mui/material";
import { animate, useInView } from "framer-motion";
import { FC, useEffect, useRef } from "react";

interface IProps {
  value: number;
  description: string;
  align?: "start" | "center" | "end";
}

const Number: FC<IProps> = (props: IProps) => {
  const descriptionFormatted = props.description.toUpperCase();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: props.align,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Counter from={0} to={props.value} prefix="%" />
        <Typography
          variant="body1"
          fontSize={23}
          fontWeight="600"
          style={{
            letterSpacing: 2,
            opacity: 0.7,
            fontFamily: "Shadows Into Light",
          }}
        >
          {descriptionFormatted}
        </Typography>
      </div>
    </div>
  );
};

export default Number;

const Counter: FC<{ from: any; to: any; prefix: string }> = ({
  from,
  to,
  prefix,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    const controls = animate(from, to, {
      duration: 2,
      ease: "easeOut",
      onUpdate(value) {
        ref.current!.textContent = `${value.toFixed(0)}${prefix}`;
      },
    });
    return () => controls.stop();
  }, [from, to, isInView, prefix]);

  return (
    <div
      style={{
        fontSize: "80px",
        fontWeight: "bold",
        minWidth: "220px",
        textAlign: "center",
      }}
      ref={ref}
    />
  );
};
