import Center from "../../components/Center";
import Footer from "../../components/Footer";
import HiveIcon from "../../components/HiveIcon";
import SlideShow from "../../components/SlideShow/SlideShow";
import { colors, theme } from "../../theme/theme";
import NumbersSection from "./NumbersSection";
import ProductsSection from "./ProductSection";
import Section from "./Section";
import ContactSection from "./ContactSection";
import SlideShowItem from "../../components/SlideShow/SlideShowItem";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import rippedBrownPaper from "../../assets/images/ripped-paper-brown.png";
import BuySection from "./BuySection";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const navigate = useNavigate();

  const slideShowItems: SlideShowItem[] = [
    {
      imagePath: require("../../assets/images/bee_box_field_1.webp"),
      title: "Lähituotantoa Porista",
      text: "Hunajaa, polttopuita ja palveluja sekä yrityksille että kuluttajalle",
      buttonText: "Katso tuotteet",
      onClick: () => navigate("/tuotteet"),
    },
    {
      imagePath: require("../../assets/images/bee_box_field_5.webp"),
      title: "Perheyritys",
      text: "Mehiläistarhausta neljännessä polvessa jo vuodesta 1920",
      buttonText: "Katso tuotteet",
      onClick: () => navigate("/tuotteet"),
    },
    {
      imagePath: require("../../assets/images/field_1.webp"),
      title: "Reilua palvelua",
      text: "Tarjoamma reilua ja luotettavaa palvelua mehiläistarhaajille sekä kuluttajille",
      buttonText: "Ota yhteyttä",
      onClick: () => navigate("/yhteystiedot"),
    },
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const parallaxRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: parallaxRef,
    offset: ["start start", "end start"],
  });
  const parallaxY = useTransform(
    scrollYProgress,
    [0, 1],
    ["0%", isMobile ? "400%" : "50%"]
  );

  return (
    <div style={{ marginTop: "-72px" }}>
      <Helmet>
        <title>Viidan Tila</title>
        <meta name="description" content="Hunajaa ja polttopuita Markus Ruususelta Porista" />
        <meta name="keywords" content="hunajaa, polttopuita, klapi, Pori"/>
      </Helmet>

      <SlideShow items={slideShowItems} height={isMobile ? "100vh" : "80vh"} />
      <div ref={parallaxRef} style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              y: parallaxY,
              x: "50%",
              scale: isMobile ? 2 : 1.3,
              top: "0px",
              width: "100%",
              display: "flex",
              alignContent: "end",
              justifyItems: "end",
              justifyContent: "end",
            }}
          >
            <HiveIcon
              color={colors.extraLightBrownBackground}
              width="100%"
              height="100%"
            />
          </motion.div>
        </div>

        <ProductsSection />

        <Center style={{ paddingTop: theme.spacing(18), position: "relative" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              justifyItems: "center",
              opacity: "0.8",
            }}
          >
            <div
              style={{
                height: "3px",
                borderRadius: "2px",
                width: "40px",
                backgroundColor: colors.primaryColor,
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: theme.spacing(18),
              paddingTop: theme.spacing(18),
              rowGap: theme.spacing(18),
            }}
          >
            <Section
              imagePath={require("../../assets/images/bee_box_1.webp")}
              title="Mehiläistarhaajille"
              text="Tarjoamme mehiläistarhaajille mehiläishoitoon tarvittavaa pesäkalustoa, tuotteita ja palveluita."
              buttonText="Lue lisää"
              isImageOnLeft={true}
              onClick={() => navigate("/mehilaistarhaajalle")}
            />

            <Section
              imagePath={require("../../assets/images/bee_flower_1_edit.webp")}
              title="Pölytyspalvelu"
              text="Tarjoamme pölytyspalvelua isoille sekä pienille maatiloille. Myös kotipuutarhureille."
              buttonText="Lue lisää"
              isImageOnLeft={false}
              onClick={() => navigate("/polytyspalvelu")}
            />
          </div>
        </Center>

        <div
          style={{
            backgroundImage: `url(${rippedBrownPaper})`,
            backgroundRepeat: "repeat-x",
            position: "relative",
            bottom: "-1px",
            rotate: "180deg",
            width: "100%",
            display: "flex",
            objectFit: "cover",
            height: "20px",
          }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <NumbersSection />
        <div
          style={{
            backgroundImage: `url(${rippedBrownPaper})`,
            backgroundRepeat: "repeat-x",
            position: "relative",
            bottom: "2px",
            width: "100%",
            display: "flex",
            objectFit: "cover",
            height: "20px",
          }}
        />
        <BuySection />
        <ContactSection />
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
