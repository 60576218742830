import { Typography, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../theme/theme";
import LinkButton from "./LinkButton";

interface Props {
  height?: number;
  title: string;
  buttonText?: string | null;
}

const Questions: FC<Props> = ({
  height = 500,
  title,
  buttonText = "Ota yhteyttä",
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const margin = isMobile ? "0px 32px 0px 32px" : "0px 16px 0px 16px";


  return (
    <div
      style={{
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",         
        overflow: "hidden",
      }}
    >
      <img
        src={require("../assets/images/firewood_short_1_wide.webp")}
        alt="header"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          filter: "brightness(50%)",
          position: "absolute",
          top: 0,
        }}
      ></img>

      <motion.div
        style={{
          margin: margin,
          position: "relative",
        }}
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 50 }}
        transition={{
          opacity: { type: "tween", ease: "linear", duration: "0.5" },
          y: { type: "spring", duration: "2", mass: 1, stiffness: 200 },
        }}
        viewport={{ once: true, amount: 0.5 }}
      >
        <Typography
          variant="h2"
          color="white"
          paddingBottom={4}
          textAlign="center"
          lineHeight={1.3}
        >
          {title}
        </Typography>
      </motion.div>
      {buttonText && (
        <LinkButton
          onClick={() => navigate("/yhteystiedot")}
          text={buttonText}
        ></LinkButton>
      )}
    </div>
  );
};

export default Questions;
