import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductButton from "./ProductButton";
import { colors, theme } from "../../theme/theme";
import ServicesButton from "./ServicesButton";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Center from "../../components/Center";

const ProductsSection = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <div
        style={{
          backgroundColor: colors.lightBrownBackground,
          height: "524px",
          left: "0",
          right: "0",
          position: "absolute",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          justifyItems: "center",
          paddingTop: theme.spacing(10),
          paddingBottom: theme.spacing(1),
          opacity: "0.8",
        }}
      >
        <div
          style={{
            height: "3px",
            borderRadius: "2px",
            width: "40px",
            backgroundColor: colors.primaryColor,
          }}
        ></div>
      </div>

      <Typography
        fontFamily="Shadows Into Light"
        variant="h2"
        textAlign="center"
        paddingBottom={6}
        sx={{
          position: "relative",
        }}
      >
        TARJOAMME
      </Typography>

      <Center>
        <Grid
          container
          columns={3}
          columnSpacing={4}
          rowSpacing={isMobile ? 6 : 0}
        >
          <Grid item md={1} xs={3}>
            <ProductButton
              imagePath={require("../../assets/images/honey.webp")}
              text="Hunajaa"
              details="Myymme tilan hunajaa suoraan kuluttajille ja kauppoihin."
              onClick={() => navigate("/tuotteet")}
            />
          </Grid>

          <Grid item md={1} xs={3}>
            <ProductButton
              imagePath={require("../../assets/images/firewood_1.webp")}
              text="Polttopuita"
              details="Kuivaa polttopuuta suoraan asiakkaalle sovittuun aikaan ja paikkaan Porin seudulla."
              onClick={() => navigate("/tuotteet")}
            />
          </Grid>

          <Grid item md={1} xs={3}>
            <ServicesButton onClick={() => navigate("/tuotteet")} />
          </Grid>
        </Grid>
      </Center>
    </div>
  );
};

export default ProductsSection;
