import Typography from "@mui/material/Typography";
import { FC } from "react";
import Section from "./Section";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../theme/theme";

interface Props {
  sections: Array<Section>;
}

export const Catalog: FC<Props> = (props: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const titleBottomPadding = isMobile ? 3 : 0;

  return (
    <div>
      {props.sections.map((section) => {
        return (
          <div
            key={section.title}
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Typography
              variant="h3"
              flex="1"
              maxWidth="300px"
              paddingTop="2px"
              paddingBottom={titleBottomPadding}
            >
              {section.title}
            </Typography>

            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginBottom: theme.spacing(6),
              }}
            >
              {section.description && (
                <Typography variant="body2" paddingBottom={3} paddingTop={1}>
                  {section.description}
                </Typography>
              )}

              {section.descriptionDetails}

              {section.items.map((item) => {
                return (
                  <div
                    key={item.name}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderColor: "lightgrey",
                      borderBottomStyle: "dashed",
                      borderWidth:
                        item === section.items[section.items.length - 1]
                          ? "0px"
                          : "1px",
                      padding: "8px 0px 8px 0px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        flexDirection: isMobile ? "column" : "row",
                        display: "flex",
                        alignItems: isMobile ? "start" : "center",
                      }}
                    >
                      <Typography variant="body1">{item.name}</Typography>
                      <Typography
                        variant="body1"
                        fontSize="14px"
                        paddingLeft={isMobile ? "0px" : "8px"}
                        paddingTop={isMobile ? "0px" : "2px"}
                      >
                        {item.description}
                      </Typography>
                    </div>
                    <Typography variant="body1">{item.price}</Typography>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Catalog;
