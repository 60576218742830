import { ShellMenuButton } from "./ShellMenuButton";
import React from "react";
import { Tab } from "./Tab";
import { useNavigate } from "react-router-dom";
import { useTabShell } from "./TabShellContext";

interface Props {
  tabs: Array<Tab>;
  onTabClick: (index: number) => void;
}

export default function ShellMenu(props: Props) {
  const navigate = useNavigate();
  const tabShellState = useTabShell();

  const onClickHandler = (index: number) => {
    if (index !== tabShellState.tabIndex) {
      tabShellState.setTabIndex(index);
    }
    navigate({
      pathname: props.tabs[index].path,
    });

    props.onTabClick(index);
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      marginBottom: "80px"
    }}>
      {props.tabs.map((x) => (
        <ShellMenuButton onClick={() => onClickHandler(props.tabs.indexOf(x))}>
          {x.text}
        </ShellMenuButton>
      ))}
    </div>
  );
}
