import Typography from "@mui/material/Typography";
import Center from "../../components/Center";
import LinkButton from "../../components/LinkButton";
import { colors, theme } from "../../theme/theme";
import { useNavigate } from "react-router-dom";

export default function BuySection() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(7),
        position: "relative",
      }}
    >
      <Center
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "3px",
              borderRadius: "2px",
              width: "40px",
              backgroundColor: colors.primaryColor,
            }}
          ></div>
        </div>

        <Typography
          fontFamily="Shadows Into Light"
          variant="h2"
          textAlign="center"
          paddingBottom={5}
          paddingTop={1}
          sx={{
            position: "relative",
          }}
        >
          OSTAMME
        </Typography>
        <div
          style={{
            maxWidth: "600px",
            textAlign: "center",
          }}
        >
          <span>Ostamme polttopuiden raaka-aineeksi </span>
          <span style={{ fontWeight: "bold" }}>koivukuitua </span>
          <span>
            hankinta ja pystykaupalla. Olemme myös kiinnostuneita ostamaan
            kaikennäköisiä ja kokoisia{" "}
          </span>
          <span style={{ fontWeight: "bold" }}>metsäpalstoja </span>
          <span>50 km säteellä Porista. Tarjoa rohkeasti!</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(6),
          }}
        >
          <LinkButton
            onClick={() => navigate("/yhteystiedot")}
            text="Ota yhteyttä"
            borderRadius="50px"
            variation="outlined"
            padding="12px 24px 12px 24px"
          ></LinkButton>
        </div>
      </Center>
    </div>
  );
}
