import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContactInfoItem from "../../components/ContactInfoItem";
import constants from "../../constants";
import { theme } from "../../theme/theme";
import { Map, Mail, Call } from "../../icons/icons";

const ContactInfo = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container columns={3} rowSpacing={isMobile ? 6 : 0}>
      <Grid item md={1} xs={3}>
        <ContactInfoItem
          title="Soita milloin vain"
          info={constants.phoneNumber}
          details={constants.name}
          icon={<Call />}
        />
      </Grid>
      <Grid item md={1} xs={3}>
        <ContactInfoItem
          title="Tule käymään"
          info={`${constants.address}, ${constants.city}`}
          icon={<Map />}
        />
      </Grid>
      <Grid item md={1} xs={3}>
        <ContactInfoItem
          title="Lähetä sähköpostia"
          info={constants.email}
          icon={<Mail />}
        />
      </Grid>
    </Grid>
  );
};

export default ContactInfo;
