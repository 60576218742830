import { Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import LinkButton from "../LinkButton";
import SlideShowItem from "./SlideShowItem";
import SlideShowButtons from "./SlideShowButtons";

const imageVariants = {
  enter: () => {
    return {
      opacity: 0,
      scale: 1,
    };
  },
  animation: {
    x: 0,
    opacity: 1,
    scale: 1.2,
    transition: {
      scale: { type: "tween", ease: "easeInOut", duration: "5" },
      opacity: { type: "tween", ease: "linear", duration: "1" },
    },
  },
  exit: () => {
    return {
      opacity: 1,
    };
  },
};

const textVariants = {
  enter: () => {
    return {
      opacity: 0,
      y: 100,
    };
  },
  animation: {
    y: 0,
    opacity: 1,
  },
  exit: () => {
    return {
      opacity: 0,
      y: 0,
      transition: { delay: 0 },
    };
  },
};

interface Params {
  items: SlideShowItem[];
  height?: string;
}

const SlideShow: FC<Params> = ({ items, height = "100vh" }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Go next slide
  const goNext = useCallback(() => {
    let nextIndex = currentImageIndex + 1;
    if (currentImageIndex >= items.length - 1) {
      nextIndex = 0;
    }
    setCurrentImageIndex(nextIndex);
  }, [currentImageIndex, items]);

  // Start timer to change slides
  useEffect(() => {
    const interval = setInterval(async () => {
      await sleep(2000);
      goNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImageIndex, goNext]);

  // Go previous slide
  const goPrev = () => {
    let nextIndex = currentImageIndex - 1;
    if (currentImageIndex === 0) {
      nextIndex = items.length - 1;
    }
    setCurrentImageIndex(nextIndex);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        height: height,
        overflow: "hidden",
      }}
    >
      <AnimatePresence>
        <motion.img
          alt="slideshow image"
          key={`${currentImageIndex}image`}
          src={items[currentImageIndex].imagePath}
          variants={imageVariants}
          initial="enter"
          animate="animation"
          exit="exit"
          transition={{
            duration: 5,
          }}
          style={{
            position: "absolute",
            objectFit: "cover",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            filter: "brightness(70%)",
          }}
        />
      </AnimatePresence>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "auto",
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            padding: "16px",
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={`${currentImageIndex}title`}
              variants={textVariants}
              initial="enter"
              animate="animation"
              exit="exit"
              transition={{
                delay: 0.4,
                duration: 1,
                type: "tween",
                ease: "easeOut",
              }}
              style={{
                fontFamily: "Shadows Into Light",
                fontSize: "min(15vw, 100px)",
                color: "white",
                fontWeight: "bold",
                lineHeight: 1,
                paddingBottom: "32px",
              }}
            >
              {items[currentImageIndex].title}
            </motion.div>
          </AnimatePresence>

          <AnimatePresence mode="wait">
            <motion.div
              key={`${currentImageIndex}text`}
              variants={textVariants}
              initial="enter"
              animate="animation"
              exit="exit"
              transition={{
                delay: 0.8,
                duration: 1,
                type: "tween",
                ease: "easeOut",
              }}
            >
              <Typography
                variant="body1"
                fontSize="min(7.6vw, 20px)"
                color="white"
                paddingBottom={10}
              >
                {items[currentImageIndex].text}
              </Typography>
            </motion.div>
          </AnimatePresence>

          <AnimatePresence mode="wait">
            <motion.div
              key={`${currentImageIndex}button`}
              variants={textVariants}
              initial="enter"
              animate="animation"
              exit="exit"
              transition={{
                delay: 1.2,
                duration: 1,
                type: "tween",
                ease: "easeOut",
              }}
            >
              <LinkButton
                text={items[currentImageIndex].buttonText}
                onClick={() => items[currentImageIndex].onClick()}
              ></LinkButton>
            </motion.div>
          </AnimatePresence>

          <div
            style={{
              position: "absolute",
              right: "16px",
              bottom: "32px",
              opacity: "0.6",
            }}
          >
            <SlideShowButtons
              nextClicked={() => goNext()}
              prevClicked={() => goPrev()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideShow;

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}