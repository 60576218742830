import { Typography } from "@mui/material";
import { theme } from "../../theme/theme";
import constants from "../../constants";
import ContactInfoItem from "../../components/ContactInfoItem";
import { Map, Mail, Call } from "../../icons/icons";

const InfoSection = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      rowGap: theme.spacing(4),
    }}>
      <Typography variant="h3">Ota yhteyttä</Typography>
      <ContactInfoItem
        title="Tule käymään"
        info={`${constants.address}, ${constants.city}`}
        icon={<Map/>}
        iconPlacement="left"
      />

      <ContactInfoItem
        title="Lähetä sähköpostia"
        info={constants.email}
        icon={<Mail/>}
        iconPlacement="left"
      />

      <ContactInfoItem
        title="Soita milloin vain"
        info={constants.phoneNumber}
        icon={<Call/>}
        iconPlacement="left"
      />
    </div>
  );
};

export default InfoSection;
