import { ButtonBase } from "@mui/material";
import { FC } from "react";
import { ArrowLeft, ArrowRight } from "../../icons/icons";

interface Props {
  nextClicked: () => void;
  prevClicked: () => void;
}

const SlideShowButtons: FC<Props> = (props: Props) => {
  return (
    <div
      style={{
        width: "50px",
        display: "flex",
        flexDirection: "column",
        rowGap: "12px",
      }}
    >
      <ButtonBase        
        onClick={() => props.nextClicked()}
        color="white"
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "30px",
          border: "2px solid white",
          background: "transparent",
        }}
      >
        <ArrowRight width={24} height={24} color="white" />
      </ButtonBase>

      <ButtonBase
        onClick={() => props.prevClicked()}
        color="white"
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "30px",
          border: "2px solid white",
          background: "transparent",
        }}
      >
        <ArrowLeft width={24} height={24} color="white" />
      </ButtonBase>
    </div>
  );
};

export default SlideShowButtons;
