import { ButtonBase, Typography } from "@mui/material";
import { FC, useState } from "react";
import { motion } from "framer-motion";
import { colors } from "../../theme/theme";
import { ArrowRight } from "../../icons/icons";

interface IProps {
  imagePath: string;
  text?: string;
  details?: string;
  onClick: () => void;
}

const ProductButton: FC<IProps> = (props: IProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <ButtonBase
      sx={{
        display: "flex",
        height: "500px",
        position: "relative",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "12px",
        overflow: "hidden",
        filter: "drop-shadow(0px 32px 32px #00000018)",
        WebkitFilter: "drop-shadow(0px 32px 32px #00000018)",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={props.onClick}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "60%",
            flex: 1,
            position: "absolute",
          }}
        >
          <motion.img
            alt="product image"
            transition={{ duration: 0.3 }}
            animate={{
              scale: isHovered ? 1.05 : 1,
              filter: isHovered ? "brightness(50%)" : "brightness(100%)",
            }}
            src={props.imagePath}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          ></motion.img>
        </div>

        <div
          style={{
            width: "100%",
            position: "absolute",
            paddingLeft: "32px",
            paddingRight: "32px",
            pointerEvents: "none",
            top: "60%",
            left: "0px",
            right: "0px",
            bottom: "0px",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize="15px"
            fontWeight="600"
            lineHeight="2"
            style={{ opacity: 0.8 }}
          >
            {props.details}
          </Typography>
        </div>

        <div
          style={{
            borderRadius: "8px",
            position: "absolute",
            top: "60%",
            width: "100%",
            margin: "-30px 0px 0px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: colors.secondaryColor,
              borderRadius: "8px",     
            }}
          >
            <Typography
              variant="body1"
              fontSize="16px"
              fontWeight="800"
              letterSpacing="1.6px"
              margin="12px 24px 12px 24px"
            >
              {props.text}
            </Typography>
          </div>
        </div>

        <motion.div
          style={{
            position: "absolute",
            display: "flex",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            borderRadius: "12px",
            alignItems: "center",
            justifyContent: "center",
          }}
          animate={{
            border: isHovered
              ? `2px solid ${colors.secondaryColor}`
              : "2px solid rgba(0, 0, 0, 0)",
          }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            style={{
              width: "40px",
              height: "40px",
              marginBottom: "160px",
            }}
            animate={{
              x: isHovered ? 0 : "-64px",
              opacity: isHovered ? 1 : 0,
            }}
          >
            <ArrowRight width={40} height={40} color="white" />
          </motion.div>
        </motion.div>
      </div>
    </ButtonBase>
  );
};

export default ProductButton;

/*
          

*/
