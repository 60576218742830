import { Typography } from "@mui/material";
import { theme } from "../../theme/theme";

const FirewoodPriceDetails = () => 
{
  const m = `irto-m\u00B3`;

  return (<div style={{
    marginBottom: theme.spacing(2),
  }}>
  <Typography variant="body1" fontWeight="bold" paddingBottom={1}>Toimitusehdot</Typography>
  <Typography variant="body2">Toimitusmaksu yli 10 km 10€ + 1 €/km</Typography>
  <Typography variant="body2">Minimitoimitus 2 {m}</Typography>
  <Typography variant="body2">2 {m} kuormiin lisätään pientoimituslisä 10€</Typography>
  </div>);
}

export default FirewoodPriceDetails;