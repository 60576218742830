import { Grid, TextField } from "@mui/material";
import { colors, theme } from "../theme/theme";
import LinkButton from "./LinkButton";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import EmailSendSuccessed from "./EmailSendSuccessed";
import LoadingScreen from "./LoadingScreen";
import EmailSendFailed from "./EmailSendFailed";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: colors.textField.background,
    ":hover": {
      backgroundColor: colors.textField.backgroundHover,
    },
  },
  ".MuiOutlinedInput-notchedOutline": { border: "none" },
}));

export const MessageForm = () => {
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [isSuccessedOpen, setIsSuccessedOpen] = useState(false);
  const [isFailedOpen, setIsFailedOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Email sended
  const emailSend = async () => {
    // Wait
    await delay(3000);

    // Clear text fields
    setIsSuccessedOpen(false);
    setName("");
    setPhoneNumber("");
    setEmail("");
    setTitle("");
    setMessage("");
    setEmailError(false);
    setMessageError(false);
  };

  // Send email
  const sendEmailHandler = async () => {
    setEmailError(false);
    setMessageError(false);
    let hasErrors = false;

    if (email === "") {
      setEmailError(true);
      hasErrors = true;
    }
    if (message === "") {
      setMessageError(true);
      hasErrors = true;
    }

    if (hasErrors === false) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name,
          phonenumber: phoneNumber,
          email: email,
          subject: title,
          text: message,
        }),
      };

      const path = `${process.env.REACT_APP_BACKEND_API_PATH!}/SendEmail`;

      setIsLoading(true);

      try {
        const response = await fetch(path, requestOptions);
        if (!response.ok) {
          setIsFailedOpen(true);
        } else {
          setIsSuccessedOpen(true);
        }
      } catch (err) {
        console.log(err);
        setIsFailedOpen(true);
      }

      setIsLoading(false);
    }
  };

  // Form page
  return (
    <form noValidate autoComplete="off">
      <EmailSendFailed
        isOpen={isFailedOpen}
        onClick={() => setIsFailedOpen(false)}
      />
      <LoadingScreen isOpen={isLoading} />

      <EmailSendSuccessed isOpen={isSuccessedOpen} onCompleted={emailSend} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: theme.spacing(4),
          alignItems: "start",
        }}
      >
        <Grid container columns={2} rowSpacing={2} columnSpacing={2}>
          <Grid item md={1} xs={2}>
            <StyledTextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth={true}
              placeholder="Nimi"
            />
          </Grid>

          <Grid item md={1} xs={2}>
            <StyledTextField
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth={true}
              required={true}
              placeholder="Puhelinnumero"
            />
          </Grid>

          <Grid item xs={2}>
            <StyledTextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth={true}
              placeholder="Sähköposti*"
              error={emailError}
              helperText={
                emailError && "Anna sähköposti johon voimme olla yhteydessä"
              }
            />
          </Grid>

          <Grid item xs={2}>
            <StyledTextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth={true}
              placeholder="Otsikko"
            />
          </Grid>
          <Grid item xs={2}>
            <StyledTextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth={true}
              required={true}
              placeholder="Kirjoita viesti*"
              multiline={true}
              minRows={10}
              error={messageError}
              helperText={messageError && "Kirjoita viesti"}
            />
          </Grid>
        </Grid>
        <LinkButton text="Lähetä viesti" onClick={sendEmailHandler} />
      </div>
    </form>
  );
};
