import { Typography } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { ArrowRight } from "../icons/icons";
import { colors } from "../theme/theme";

interface Props {
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  variation?: "filled" | "filled2" | "outlined";
  padding?: string | undefined;
  borderRadius?: string | undefined;
}

const LinkButton: FC<Props> = (props: Props) => {
  const [isHovered, setHovered] = useState(false);

  const isFilled =
    props.variation === null ||
    props.variation === undefined ||
    props.variation === "filled";
  const isFilled2 = props.variation === "filled2";

  // Filled styles
  let background = colors.linkButtonFilled.background;
  let border = "0";
  let foreground = colors.linkButtonFilled.foregroud;

  if (isFilled2) {
    background = colors.linkButtonFilled2.background;
    foreground = colors.linkButtonFilled2.foregroud;
  } else if (isFilled === false) {
    background = "transparent";
    border = `2px solid ${colors.linkButtonOutlined.border}`;
    foreground = colors.linkButtonOutlined.foregroud;
  }

  return (
    <ButtonBase
      onClick={props.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        padding:
          props.padding === null || props.padding === undefined
            ? "20px 40px 20px 40px"
            : props.padding,
        borderRadius:
          props.borderRadius === null || props.borderRadius === undefined
            ? "8px"
            : props.borderRadius,
        border: border,
        background: background,
        type: props.type,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <motion.div
          style={{
            position: "absolute",
            y: 2
          }}
          transition={{ duration: 0.2 }}
          animate={{
            x: isHovered ? "0px" : "-20px",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <ArrowRight color={foreground} />
        </motion.div>

        <motion.div
          transition={{ duration: 0.2 }}
          animate={{
            x: isHovered ? "20px" : "0px",
          }}
        >
          <Typography
            variant="button"
            style={{
              color: foreground,
              fontSize: "16px",
              position: "relative",
              margin: "0px 8px 0px 8px",
            }}
          >
            {props.text}
          </Typography>
        </motion.div>

        <motion.div
          style={{ y: 1 }}
          transition={{ duration: 0.2 }}
          animate={{
            x: isHovered ? "20px" : "0px",
            opacity: isHovered ? 0 : 1,
          }}
        >
          <ArrowRight color={foreground} />
        </motion.div>
      </div>
    </ButtonBase>
  );
};

export default LinkButton;
