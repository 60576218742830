import Modal from "@mui/material/Modal";
import { FC } from "react";
import { CircularProgress } from "@mui/material";

interface Props {
  isOpen: boolean;
}

const LoadingScreen: FC<Props> = (props: Props) => {

  return (
    <Modal open={props.isOpen}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <CircularProgress size={100} color="success"/>
      </div>
    </Modal>
  );
};

export default LoadingScreen;
