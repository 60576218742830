import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

const TabShellContext = createContext<TabShellState>({} as TabShellState);

interface Props {
  children?: ReactNode | ReactNode[];
  initialTabIndex?: number;
}

export type TabShellState = {
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const TabShellProvider: FC<Props> = (props: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    props.initialTabIndex ?? 0
  );

  const tabShellState: TabShellState = {
    tabIndex: currentTabIndex,
    setTabIndex: setCurrentTabIndex,
  };

  return (
    <TabShellContext.Provider value={tabShellState}>
      {props.children}
    </TabShellContext.Provider>
  );
};

export const useTabShell = () => {
  return useContext(TabShellContext);
};
