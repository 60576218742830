import useMediaQuery from "@mui/material/useMediaQuery";
import { FC, ReactNode } from "react";
import { theme } from "../theme/theme";

interface Props {
  children: ReactNode | ReactNode[];
  style?: any;
}

const Center: FC<Props> = (props: Props) => {

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const margin = isMobile ? "0px 16px 0px 16px" : "0px 32px 0px 32px";

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "auto",
        ...props.style,
      }}
    >
      <div style={{ margin: margin }}>{props.children}</div>
    </div>
  );
};

export default Center;