import Center from "../../components/Center";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { colors, theme } from "../../theme/theme";
import ContactInfo from "./ContactInfo";
import SendMessage from "./SendMessage";
import Map from "../../components/Map";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <div>
      <Helmet>
        <title>Viidan Tila - Yhteystiedot</title>
        <meta name="description" content="Viidan tilan yhteystiedot"/>
        <meta name="keywords" content="Viidan-Tila, Markus Ruusunen, Ota yhteyttä, Pori"/>
      </Helmet>
     <Header
        title="Yhteystiedot"
        imagePath={require("../../assets/images/markus.webp")}
        height={500}
      />
      <Center>
        <div style={{ paddingTop: theme.spacing(12) }}>
          <ContactInfo />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            opacity: "0.8",
            paddingTop: theme.spacing(9),
            paddingBottom: theme.spacing(9),
          }}
        >
          <div
            style={{
              height: "3px",
              borderRadius: "2px",
              width: "40px",
              backgroundColor: colors.primaryColor,
            }}
          ></div>{" "}
        </div>
        <div style={{ paddingBottom: theme.spacing(12) }}>
          <SendMessage />
        </div>
      </Center>
      <Map />
      <Footer />
    </div>
  );
};

export default ContactPage;
