import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { colors, theme } from "../../theme/theme";
import Number from "../../components/Number";
import Center from "../../components/Center";

const NumbersSection = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        backgroundColor: colors.lightBrownBackground,
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
      }}
    >
      <Center>
        <Grid
          container
          columns={3}
          columnSpacing={4}
          rowSpacing={isMobile ? 18 : 0}
        >
          <Grid item md={1} xs={3}>
            <Number
              value={100}
              description="Kotimaista"
              align={isMobile ? "center" : "start"}
            />
          </Grid>

          <Grid item md={1} xs={3}>
            <Number value={100} description="Lähituotantoa" align="center" />
          </Grid>

          <Grid item md={1} xs={3}>
            <Number
              value={100}
              description="Positiivista asennetta"
              align={isMobile ? "center" : "end"}
            />
          </Grid>
        </Grid>
      </Center>
    </div>
  );
};

export default NumbersSection;
