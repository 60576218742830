import { FC } from "react";

interface Props {
  color?: string,
  width?: number,
  height?: number,
}

export const Call = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="32"
    width="32"
    viewBox="0 0 48 48"
    stroke="white"
    fill="white"
    strokeWidth="0px"
  >
    <path d="M39.75 42q-6.1 0-12.125-3T16.8 31.2Q12 26.4 9 20.375 6 14.35 6 8.25q0-.95.65-1.6Q7.3 6 8.25 6h7q.7 0 1.225.475.525.475.675 1.275l1.35 6.3q.1.7-.025 1.275t-.525.975l-5 5.05q2.8 4.65 6.275 8.1Q22.7 32.9 27.1 35.3l4.75-4.9q.5-.55 1.15-.775.65-.225 1.3-.075l5.95 1.3q.75.15 1.25.75T42 33v6.75q0 .95-.65 1.6-.65.65-1.6.65Zm-28.3-23.4 4.05-4.1L14.35 9H9q0 1.95.6 4.275t1.85 5.325ZM39 39v-5.35l-5.15-1.05-3.95 4.15q2.05.95 4.45 1.55 2.4.6 4.65.7Zm-9.1-2.25ZM11.45 18.6Z" />
  </svg>
);

export const Mail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="32"
    width="32"
    viewBox="0 0 48 48"
    stroke="white"
    fill="white"
    strokeWidth="0px"
  >
    <path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm34-26.25-16.2 10.6q-.2.1-.375.175-.175.075-.425.075t-.425-.075q-.175-.075-.375-.175L7 13.75V37h34ZM24 21.9 40.8 11H7.25ZM7 13.75v.35-1.975.025V11v1.15-.05 2-.35V37Z" />
  </svg>
);

export const Map = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="32"
    width="32"
    viewBox="0 0 48 48"
    stroke="white"
    fill="white"
    strokeWidth="0px"
  >
    <path d="m29.6 41.65-12.15-4.3L8.5 40.9q-.85.45-1.675-.05Q6 40.35 6 39.35v-27.9q0-.65.375-1.15.375-.5.975-.75l9.1-3.2q.5-.15 1-.15t1 .15L30.6 10.6l8.9-3.55q.85-.4 1.675.075Q42 7.6 42 8.6v28.25q0 .55-.375.95-.375.4-.925.6l-9.1 3.25q-.5.15-1 .15t-1-.15Zm-.7-3.4V13l-9.8-3.3v25.25Zm3 0L39 35.9V10.3L31.9 13ZM9 37.65l7.1-2.7V9.7L9 12.05ZM31.9 13v25.25ZM16.1 9.7v25.25Z" />
  </svg>
);

export const ArrowRight : FC<Props>= (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={props.height ?? 24}
    width={props.width ?? 24}
    strokeWidth={0}
    fill={props.color ?? "black"}
    viewBox="0 0 48 48"
  >
    <path d="m24 40.55-3.35-3.4 10.85-10.8H7.45v-4.7H31.5L20.65 10.8 24 7.45 40.55 24Z" />
  </svg>
);

export const ArrowLeft : FC<Props>= (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={props.height}
    width={props.width}
    strokeWidth={0}
    fill={props.color}
    viewBox="0 0 48 48"
  >
    <path d="M24 40.55 7.45 24 24 7.45l3.35 3.3-10.85 10.9h24.05v4.7H16.5L27.35 37.2Z" />
  </svg>
);
